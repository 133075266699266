import axios from 'axios';

import * as ld from 'lodash-es/lodash';

const appendCell = (row, text) => {
    const cell = document.createElement('td');
    cell.textContent = text;

    row.appendChild(cell);
};

const showResult = ($container, esiResult) => {
    const $resultStatus = $container.find('#result-status');
    const $resultTable = $container.find('#result-table');

    if (esiResult.failureReason) {
        $resultStatus.text(esiResult.failureReason);
        return;
    }

    $resultStatus.text('Successful lookup');

    $resultTable.empty();
    for (const address of esiResult.addresses) {
        const row = document.createElement('tr');

        appendCell(row, address.esiid);
        appendCell(row, address.clean_Address);
        appendCell(row, address.city);
        appendCell(row, address.premise_Type);
        appendCell(row, address.utility);

        $resultTable.append(row);
    }

    $resultTable.show();

    
}

const onClickOption = ($container) => event => {
    const $resultTable = $container.find('#result-table');
    const $resultStatus = $container.find('#result-status');
    const $numPages = $container.find('#num-pages');

    $resultTable.hide();
    $resultStatus.text('Loading...');

    axios.get(`${window.location}/EsiIdLookupWithRawAddress?address=${encodeURIComponent(event.target.textContent)}&numPages=${$numPages.val()}`)
        .then(res => {
            showResult($container, res.data);
            
        })
        .catch(err => {
            $resultStatus.text('Something went wrong');
        });
};

const onAddressAutocompleteInput = ($container, $addressOptions) => event => {
    axios.get(`${window.location}/locationautocomplete?fragment=${encodeURIComponent(event.target.value)}`)
        .then(res => {
            $addressOptions.empty();

            for (const addressInfo of res.data) {
                const optionElt = document.createElement('li');

                const buttonElt = document.createElement('button');
                buttonElt.textContent = addressInfo.address;
                buttonElt.dataset.placeId = addressInfo.placeId;
                $(buttonElt).on('click', onClickOption($container));

                optionElt.appendChild(buttonElt);

                $addressOptions.append(optionElt);
            }
        })
        .catch(err => {
            alert('Something went wrong!');
        });
}

const onSubmitAddressRawForm = ($container, $addressRaw) => event => {
    event.preventDefault();

    const $resultTable = $container.find('#result-table');
    const $resultStatus = $container.find('#result-status');
    const $numPages = $container.find('#num-pages');

    $resultTable.hide();
    $resultStatus.text('Loading...');

    axios.get(`${window.location}/EsiIdLookupWithRawAddress?address=${encodeURIComponent($addressRaw.val())}&numPages=${$numPages.val()}`)
        .then(res => {
            showResult($container, res.data);
        })
        .catch(err => {
            $resultStatus.text('Something went wrong');
        })
}

const initAddressTestPage = () => {
    const $container = $('#address-test-page');
    if (!$container.length) {
        return;
    }

    const $addressAutocompleteInput = $container.find('#address-autocomplete');
    const $addressOptions = $container.find('#address-autocomplete-options');

    const $addressRaw = $container.find('#address-raw');
    const $addressRawForm = $container.find('#address-raw-form');

    const debouncedAddressInput = ld.debounce(onAddressAutocompleteInput($container, $addressOptions), 500);
    $addressAutocompleteInput.on('keyup', debouncedAddressInput);

    $addressRawForm.on('submit', onSubmitAddressRawForm($container, $addressRaw))
};  

export default initAddressTestPage;
