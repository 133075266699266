import { axiosGet, axiosPost } from '../api/api.js';

const initPlanLoader = () => {
    $("#verify-button").click(_verifyPlanData);
    $("#new-plan-button").click(_openConfirmationModal);
    $("#refresh-plan-button").click(_createPlanData);
    $("#select-all-upsert").click(_selectAllUpsert);
    $("#select-all-delete").click(_selectAllDelete);
    $("#deselect-all").click(_deselectAll);
    $("#submit-button").click(_createPlanData);
}

const _toggleDelete = (event) => {
    var uploadInput = $(event.target);
    var deleteInput = uploadInput.parent().parent().find(".delete-container").find(".delete");
    var deleteVariantCode = uploadInput.parent().parent().find(".dvc").html();

    // If there is a variant code in the delete section
    if (deleteVariantCode) {
        deleteInput.prop("checked", uploadInput.prop("checked"));
        deleteInput.prop("disabled", uploadInput.prop("checked"));
    }
}

const _deselectAll = () => {
    $(".plan").map(function () {
        $(this).find('.upload').first().prop("checked", false);
        $(this).find('.delete').first().prop("checked", false);
        $(this).find('.delete').prop("disabled", false);
    })
}

const _selectAllUpsert = () => {
    $(".plan").map(function () {
        if ($(this).find('.variant').first().html() && !$(this).find('.upload').first().prop("disabled")) {
            $(this).find('.upload').first().prop("checked", true);
            $(this).find('.delete').prop("checked", true);
            $(this).find('.delete').prop("disabled", true);
        }
    })
}

const _selectAllDelete = () => {
    $(".plan").map(function () {
        if ($(this).find('.dvc').first().html() && !$(this).find('.delete').first().prop("disabled")) {
            $(this).find('.delete').first().prop("checked", true);
        }
    })
}

const _openConfirmationModal = () => {
    $("#confirmation-modal").modal("toggle");
}

const _createPlanData = () => {
    var verifyRequest = '/api/planadmin/createplandata';
    $("#button-zone").hide();
    $("#spinner").show();
    $("#error-list").empty();
    $("#error-message").hide();
    $("#success-title").text("");
    $("#confirmation-modal").modal("hide");

    var data = {
        "items": []
    };

    $(".plan").map(function () {
        if ($(this).find('.upload').first().is(':checked') || $(this).find('.delete').first().is(':checked')) {
            var term = $(this).find('.term').first().html() != "" ? $(this).find('.term').first().html() : 0;
            var brokerId = $(this).find('.bid').first().html() != "" ? $(this).find('.bid').first().html() : 0;
            data.items.push({
                "update": $(this).find('.upload').first().is(':checked'),
                "delete": $(this).find('.delete').first().is(':checked'),
                "marketSegmentGroup": $(this).find('.msg').first().html(),
                "term": term,
                "utility": $(this).find('.utility').first().html(),
                "settlementZone": $(this).find('.sz').first().html(),
                "marketSegmentAnalysisId": $(this).find('.msaid').first().html(),
                "brokerId": brokerId,
                "deleteVariantCode": $(this).find(".dvc").first().html()
            })
        }
    })

    axiosPost(verifyRequest, data, _createPlanSuccess, _createPlanFailure);
}

const _createPlanSuccess = (response) => {
    $("#button-zone").show();
    $("#spinner").hide();
    $("#new-plan-button").hide();
    $("#success-list").empty();
    $("#select-all-upsert").hide();
    $("#select-all-delete").hide();
    $("#deselect-all").hide();
    $("#success-title").html(`Plan Loader Job Started. Check your email for progress updates.`);
}

const _createPlanFailure = (error) => {
    $("#spinner").hide();

    var listItems = "";

    if (error) {
        listItems += `<li class="text-danger">${error.message}</li>`
    }

    if (response) {
        if (response.data.errorMessage) {
            listItems += `<li class="text-danger">${response.data.errorMessage}</li>`
        }
        for (let i = 0; i < response.data.validationErrors.length; i++) {
            listItems += `<li class="text-danger">${response.data.validationErrors[i]}</li>`
        }
        for (let i = 0; i < response.data.parseErrors.length; i++) {
            listItems += `<li class="text-danger">${response.data.parseErrors[i]}</li>`
        }
    }

    $("#error-list").append(listItems);
    console.log(error);
}

const _verifyPlanData = () => {
    var url = '/api/planadmin/validateplandata';
    var isTest = document.getElementById('isTest').checked
    var planJsonNumber = $("#planJsonNumber").val();
    var data = {
        IsTest: isTest,
        PlanJsonNum: parseInt(planJsonNumber)
    }
    $("#error-list").empty();
    $("#success-list").empty();
    $("#success-title").text("");
    $("#new-plan-button").hide();
    $("#button-zone").hide();
    $("#spinner").show();
    $("#select-all-upsert").hide();
    $("#select-all-delete").hide();
    $("#deselect-all").hide();
    $("#upsert-result-list").empty();

    axiosPost(url, data, _verificationSuccess, _verificationFailure);
}

const _verificationSuccess = (response) => {
    $("#button-zone").show();
    $("#spinner").hide();
    $("#select-all-upsert").show();
    $("#select-all-delete").show();
    $("#deselect-all").show();

    $("#success-title").text(`Successfully parsed and verified ${response.data.plans.length} plans`);
    var successListItems = `
<thead>
    <tr>
        <th>Upload</th>
        <th>Product Status</th>
        <th>Variant Status</th>
        <th>Variant Code</th>
        <th>Market Segment Group</th>
        <th>Market Segment Analysis ID</th>
        <th>Market Segment</th>
        <th>Market Segment Id</th>
        <th>Term</th>
        <th>Settlement Zone</th>
        <th>Catalog</th>
        <th>Utility</th>
        <th>Broker Id</th>
        <th>BID</th>
        <th>Green Status</th>
        <th>Promoted</th>
        <th>Price</th>
        <th>Price Units</th>
        <th class="delete-column-background">Delete</th>
        <th class="delete-column-background">Old Variant Code</th>
        <th class="delete-column-background">Old Variant Status</th>
        <th class="delete-column-background">Old Broker Id</th>
        <th class="delete-column-background">Old Market Segment Id</th>
        <th class="delete-column-background">Old Price</th>
        <th class="delete-column-background">Old Price Units</th>
    </tr>
</thead>
<tbody>`

    for (let i = 0; i < response.data.plans.length; i++) {
        var plan = response.data.plans[i];
        //var price = Math.round(plan.price * 100) / 100
        var price = (plan.price * 100) / 100
        var catalog = plan.catalog == "Residential" ? "RES" : plan.variantCode ? "SMB" : "";
        var greenPlan = plan.greenPlan == true ? "GREEN" : "";
        var sameVariantCode = plan.variantCode == plan.deleteVariantCode;
        successListItems += `<tr class="plan">
            <td class="upload-container"><input type="checkbox" class="upload" ${plan.variantCode ? '' : 'disabled'}/></td>
            ${plan.updatingProduct ? "<td class='validation-update'>Update</td>" : plan.variantCode ? "<td class='validation-new'>New</td>" : "<td class='validation-new'></td>"}
            ${plan.updatingVariant ? "<td class='validation-update'>Drop/Create</td>" : plan.variantCode ? "<td class='validation-new'>New</td>" : "<td class='validation-new'></td>"}
            <td class="variant text-white bold ${sameVariantCode ? "bg-success" : "bg-danger"}">${plan.variantCode ? plan.variantCode : ""}</td>
            <td class="msg">${plan.marketSegmentGroup ? plan.marketSegmentGroup : ""}</td>
            <td class="msaid">${plan.marketSegmentAnalysisId ? plan.marketSegmentAnalysisId : ""}</td>
            <td class="ms">${plan.marketSegment ? plan.marketSegment : ""}</td>
            <td class="msid">${plan.marketSegmentId ? plan.marketSegmentId : ""}</td>
            <td class="term">${plan.term ? plan.term : ""}</td> 
            <td class="sz">${plan.settlementZone ? plan.settlementZone : ""}</td> 
            <td class="catalog">${catalog}</td> 
            <td class="utility">${plan.utility ? plan.utility : ""}</td> 
            <td class="bid">${plan.brokerId ? plan.brokerId : ""}</td>
            <td class="bid-display">BID-${plan.brokerId ? plan.brokerId : ""}</td>
            <td class="gp">${greenPlan}</td>
            <td class="promoted">${plan.promoted ? plan.promoted : ""}</td>
            <td class="dt">${price > 0 ? price : ''}</td>
            <td class="dt">${plan.displayType ? plan.displayType : ""}</td>
            <td class="delete-container"><input type="checkbox" class="delete" ${plan.deleteVariantCode ?  '' : 'disabled'}/></td>
            <td class="dvc text-white bold ${sameVariantCode ? "bg-success" : "bg-danger"}">${plan.deleteVariantCode ? plan.deleteVariantCode : ''}</td>
            <td class="drop">${plan.deleteVariantCode ? 'Drop' : '' }</td>
            <td class="dbid">${plan.deleteBrokerId ? plan.deleteBrokerId : 0}</td>
            <td class="dmsid">${plan.deleteMarketSegmentId ? plan.deleteMarketSegmentId : ''}</td>
            <td class="dp">${plan.deletePrice ? plan.deletePrice : ''}</td>
        </tr>`
    }

    successListItems += "</tbody>"
    $("#success-list").append(successListItems);

    if (response.data.success) {
        $("#new-plan-button").prop("disabled", false);
        $("#new-plan-button").show();
        $(".upload").click(_toggleDelete);
    } else {
        if (response.data.allowErrors) {
            $("#new-plan-button").prop("disabled", false);
            $("#new-plan-button").show();
        }
        var listItems = "";
        if (response.data.errorMessage) {
            listItems += `<li class="text-danger">${response.data.errorMessage}</li>`
        }
        for (let i = 0; i < response.data.validationErrors.length; i++) {
            listItems += `<li class="text-danger">${response.data.validationErrors[i]}</li>`
        }
        for (let i = 0; i < response.data.parseErrors.length; i++) {
            listItems += `<li class="text-danger">${response.data.parseErrors[i]}</li>`
        }
        $("#error-list").append(listItems);
    }
}

const _verificationFailure = (error) => {
    console.error("Error validating plan data " + error);
    $("#errorMessage").text(error.response.data.errorMessage);
    $("#errorMessage").show();
    $("#button-zone").show();
    $("#spinner").hide();

    var listItems = "";
    if (error.response.data.errorMessage) {
        listItems += `<li class="text-danger">${error.response.data.errorMessage}</li>`
    }
    for (let i = 0; i < error.response.data.validationErrors.length; i++) {
        listItems += `<li class="text-danger">${error.response.data.validationErrors[i]}</li>`
    }
    for (let i = 0; i < error.response.data.parseErrors.length; i++) {
        listItems += `<li class="text-danger">${error.response.data.parseErrors[i]}</li>`
    }
    $("#error-list").append(listItems);
}

export default initPlanLoader;