import axios from 'axios';

const initNewsletter = () => {
    const $form = $('.js-newsletter-sign-up');

    $form.find('input').on('invalid', event => {
        event.preventDefault();

        $(event.target).find('.invalid-feedback').text($form.data('invalidEmailMessage')).show();
        $(event.target).addClass('is-invalid');
    });

    $form.on('submit', event => {
        event.preventDefault();

        const $triggeringForm = $(event.target);

        const $input = $triggeringForm.find('[name=email]');
        const $errorDisplay = $triggeringForm.find('.invalid-feedback');

        $input.removeClass('is-invalid');
        $errorDisplay.hide();

        const email = $input.val();

        // attempt to submit
        axios.post('/api/newsletter', {
            email,
            listId: $triggeringForm.data('listId')
        })
            .then(res => {
                $(`#${$triggeringForm.data('domId')}-success-modal`).modal('show');
            })
            .catch(err => {
                $errorDisplay.text($triggeringForm.data('errorMessage')).show();
            })
    });
};

export default initNewsletter;
