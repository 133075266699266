const initTestimonials = () => {
    if ($('.testimonial-carousel').length) {
        tns({
            container: '.testimonial-carousel',
            items: 1,
            axis: 'vertical',
            loop: false,
            navPosition: "bottom",
            autoHeight: false,
            nav: false,
            controlsText: ['<div class="cv-prev"><span class="de-arrow-left" aria-hidden="true"></span> </div>','<div class="cv-next"> <span class="de-arrow-right" aria-hidden="true"></span></div>'],
            responsive: {
                640: {
                    items: 2
                }
            }
        });
    }
};

export default initTestimonials;
