import axios from 'axios';

export class VerifyAddressApi {
    static suggestAddresses = async (simpleAddress, token) => {
        try {
            const response = await axios.post("/api/verifyaddress/suggest", simpleAddress, {
                headers: {
                    'RequestVerificationToken': token
                }
            });
            return response.data;
        } catch (error) {
            console.error(error, error.stack);
            return null;
        }
    }
}