import * as ld from 'lodash-es/lodash';

const dateFormat = 'mm/dd/yy';

const initViewAllPlansPage = () => {
    $("#catalog").click(() => { sortString(0) });
    $("#market-segment-group").click(() => { sortString(1) });
    $("#market-segment-analysis-id").click(() => { sortNumber(2) });
    $("#term").click(() => { sortNumber(3) });
    $("#settlement-zone").click(() => { sortString(4) });
    $("#utility").click(() => { sortString(5) });
    $("#energy-price").click(() => { sortNumber(6) });
    $("#tariff-500-price").click(() => { sortNumber(7) });
    $("#tariff-1000-price").click(() => { sortNumber(8) });
    $("#tariff-2000-price").click(() => { sortNumber(9) });
    $("#total-price").click(() => { sortNumber(10) });

    const $container = $('.js-view-all-plans-container');
    if (!$container.length) {
        return;
    }

    const $snapshotDate = $container.find('#snapshotDate');
    const $snapshotListWrap = $container.find('.js-snapshot-list-wrap')
    const $snapshotList = $snapshotListWrap.find('.js-snapshot-list');
    
    const availableSnapshots = $snapshotDate.data('availableSnapshots');
    const groupedSnapshots = ld.groupBy(availableSnapshots, snapshot => new Date(snapshot.Created).toDateString());
    const availableDates = ld.keysIn(groupedSnapshots);

    $snapshotDate.datepicker({
        beforeShowDay: date => [availableDates.includes(date.toDateString())],
        dateFormat
    });

    $snapshotDate.on('change', event => {
        const date = jQuery.datepicker.parseDate(dateFormat, event.target.value).toDateString();

        const snapshotsForDate = groupedSnapshots[date];
        if (snapshotsForDate.length === 1) {
            window.location = `?snapshot=${encodeURIComponent(snapshotsForDate[0].Filename)}`;
        } else {
            $snapshotList.empty();

            for (const snapshot of snapshotsForDate) {
                const li = document.createElement('li');

                const a = document.createElement('a');
                a.href = `?snapshot=${encodeURIComponent(snapshot.Filename)}`;
                a.textContent = `${snapshot.Created} from ${snapshot.CreateReason}`

                li.appendChild(a);

                $snapshotList.append(li);
            }

            $snapshotListWrap.show();
        }
    });
}

const sortNumber = (category) => {
    var table, rows, switching, i, x, y, shouldSwitch;
    table = document.getElementById("all-active-plans");
    switching = true;

    while (switching) {
        switching = false;
        rows = table.rows;
        for (i = 1; i < (rows.length - 1); i++) {
            shouldSwitch = false;

            x = rows[i].getElementsByTagName("TD")[category];
            y = rows[i + 1].getElementsByTagName("TD")[category];

            if (parseFloat(x.innerHTML) > parseFloat(y.innerHTML)) {
                shouldSwitch = true;
                break;
            }
        }
        if (shouldSwitch) {
            rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
            switching = true;
        }
    }
}

const sortString = (category) => {
    var table, rows, switching, i, x, y, shouldSwitch;
    table = document.getElementById("all-active-plans");
    switching = true;

    while (switching) {
        switching = false;
        rows = table.rows;
        for (i = 1; i < (rows.length - 1); i++) {
            shouldSwitch = false;

            x = rows[i].getElementsByTagName("TD")[category];
            y = rows[i + 1].getElementsByTagName("TD")[category];

            if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                shouldSwitch = true;
                break;
            }
        }
        if (shouldSwitch) {
            rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
            switching = true;
        }
    }
}

export default initViewAllPlansPage;