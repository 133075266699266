import axios from 'axios';
import { initializeAutopayPaymentForm } from '../helpers/enrollment-payment-form.js';
import getRollbar from '../globals/rollbar';

const handleError = ($form, error) => {
    const messageContainer = $form.find('#stripe-error-message');
    messageContainer.text(error.message);

    toggleForm($form, false);
}

const submitAutopay = async ($form, stripe, elements) => {
    toggleForm($form, true);

    const csrfToken = $form[0].querySelector('input[name="__RequestVerificationToken"]')?.value;
    const returnUrl = $form[0].dataset.redirectUrl;

    try {
        const { error: submitError } = await elements.submit();

        if (submitError) {
            handleError($form, submitError);
            return;
        }

        const res = await fetch("/api/cart/create-autopay-intent", {
            headers: {
                "Content-Type": "application/json",
                RequestVerificationToken: csrfToken
            },
            method: "POST",
        });

        const { success, errorMessage, clientSecret } = await res.json();

        if (!success) {
            handleError($form, errorMessage);
            return;
        }

        const params = {
            elements,
            clientSecret: clientSecret,
            confirmParams: {
                return_url: returnUrl
            },
        }
        const { error } = await stripe.confirmSetup(params);

        if (error) handleError($form, error);
    } catch (err) {
        handleError($form, err);
        console.error(err);
    } finally {
        toggleForm($form, false);
    }
}

const toggleForm = ($form, disable) => {
    const $submitBtn = $form.find('button[type=submit]');
    const $skipBtn = $form.find('.js-skip-button');
    const $submitSpinner = $submitBtn.find('.js-spinner');

    $submitBtn.prop('disabled', disable);
    $skipBtn.prop('disabled', disable);

    if (disable)
        $submitSpinner.show();
    else
        $submitSpinner.hide();
}

const initAutoPayEnrollment = async () => {
    const $container = $('.js-autopay-enrollment');
    if (!$container.length) {
        return;
    }

    const $form = $container.find('#autopay-enrollment-form');
    const $submitBtn = $container.find('button[type=submit]');
    const $skipBtn = $container.find('.js-skip-button');
    const $submitSpinner = $submitBtn.find('.js-spinner');
    const $skipSpinner = $skipBtn.find('.js-spinner');

    const { stripe, elements } = await initializeAutopayPaymentForm($form[0]);

    $form.on('submit', async event => {
        event.preventDefault();
        await submitAutopay($form, stripe, elements);
    });


    $skipBtn.on('click', event => {
        event.preventDefault();

        $submitBtn.prop('disabled', true);
        $skipBtn.prop('disabled', true);
        $skipSpinner.show();

        const endpoint = !!$form.data('csr') ? 'csrSkipAutopay' : 'skipAutopay';
        const csrfToken = $form.find('input[name="__RequestVerificationToken"]').val();

        axios.post(`/api/cart/${endpoint}`, null, {
            headers: {
                RequestVerificationToken: csrfToken
            }
        }).then(res => {
            $form.trigger("skipAutoPay");
            window.location = res.data.href;
        }).catch(err => {
            $submitBtn.prop('disabled', false);
            $skipBtn.prop('disabled', false);
            $skipSpinner.hide();
            $form.trigger("skipAutoPayError", err);
            $form.find('.js-error-modal').modal('show');
        })
    });
};

export default initAutoPayEnrollment;
