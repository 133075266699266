import axios from 'axios';

const initCustomerLookupPage = () => {
    const $form = $('.js-customer-lookup-form');
    if (!$form.length) {
        return;
    }

    const $enrollmentNumber = $form.find('#enrollment-number');
    const $submitBtn = $form.find('button[type=submit]');
    const $spinner = $('.js-customer-lookup-spinner');
    const $results = $('.js-customer-lookup-results');

    $form.on('submit', event => {
        event.preventDefault();

        $submitBtn.prop('disabled', true);
        $spinner.show();

        axios.get(`/api/customerlookup/getaccount/${encodeURIComponent($enrollmentNumber.val())}`)
            .then(res => {
                $results.text(JSON.stringify(res.data, null, 4));
            })
            .catch(() => {
                $results.text('Something went wrong. Please try again.');
            })
            .finally(() => {
                $spinner.hide();
                $submitBtn.prop('disabled', false);
            });
    });
};

export default initCustomerLookupPage;
