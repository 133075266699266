const initPlanBillHistory = () => {
    const $container = $('.bill-history');

    if (!$container.length) {
        return;
    }

    $container.find('.mobile-accordion .js-expand').click(event => {
        event.preventDefault();

        $(event.target).closest('.mobile-accordion').toggleClass('open');
    });
};

export default initPlanBillHistory;
