import { loadStripe } from '@stripe/stripe-js';

const initStripePaymentForm = async () => {
    const stripeForm = document.querySelector('#stripe-payment-form');

    const publicKey = stripeForm?.dataset?.publicKey;
    const clientSecret = stripeForm?.dataset?.clientSecret;

    if (stripeForm == null || publicKey == null || clientSecret == null)
        return;

    const stripe = await loadStripe(publicKey);
    const elements = createStripeElements(stripe, clientSecret);

    const addressElement = createAddressElement(elements);
    const paymentElement = createPaymentElement(elements);

    addressElement.mount('#stripe-address-element');
    paymentElement.mount('#stripe-payment-element');

    stripeForm.addEventListener('submit', async (event) => {
        event.preventDefault();

        //const { error } = await stripe.confirmPayment({
        //    elements,
        //    confirmParams: {
        //        return_url: 'https://localhost:5000',
        //    },
        //});

        const { error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: 'https://localhost:5000',
            },
        });

        if (error) {
            const messageContainer = stripeForm.querySelector('#stripe-error-message');
            messageContainer.textContent = error.message;
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    });
}

const createStripeElements = (stripe, clientSecret) => {
    const fonts = [{
        cssSrc: 'https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap'
    }]

    const appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: '#0570de',
            colorBackground: '#ffffff',
            colorText: '#30313d',
            colorDanger: '#df1b41',
            fontFamily: 'Outfit, sans-serif',
            fontSmooth: 'always',
            colorText: '#000000',
            colorPrimary: '#86b7fe',
            colorSuccess: '#198754',
            colorDanger: '#dc3545',
            spacingUnit: '4px',
            fontSizeBase: '20px',
            fontLineHeight: '30px',
            borderRadius: '0',
            gridColumnSpacing: '1rem',
            gridRowSpacing: '1rem'
        }
    };

    const elements = stripe.elements({ clientSecret, appearance, fonts });
    return elements;
}

const createAddressElement = (elements) => {
    const options = {
        mode: 'billing',
        blockPoBox: true,
        allowedCountries: ['US'],
        defaultValues: {
            name: 'John Doe',
            email: 'john.doe@email.cz',
            address: {
                line1: '55 East 10th Street',
                city: 'New York',
                state: 'NY',
                postal_code: '10003',
                country: 'US'
            }
        }
    };

    const addressElement = elements.create('address', options);
    return addressElement;
}

const createPaymentElement = (elements) => {
    const options = {
        fields: {
            billingDetails: 'never'
        }
    }

    const paymentElement = elements.create('payment');
    return paymentElement;
}

export default initStripePaymentForm;