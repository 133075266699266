import axios from 'axios';

const tryParseJson = text => {
    try {
        return JSON.parse(text);
    } catch {
        return undefined;
    }
}

class FullAddressModalComponent {
    constructor() {
        this.$container = $('#editServiceAddressModal');
        if (!this.$container.length) {
            return;
        }
        this.$addressModalBody = this.$container.find('#address-modal-body');
        this.$loader = this.$container.find('#address-modal-loader');

        this.$form = this.$container.find('#address-modal-body');

        this.$submitBtn = this.$form.find('button[type=submit]');

        this.$esiIdInput = this.$form.find('[name=esiid]');
        this.$utilityNameInput = this.$form.find('[name=utility-name]')
        this.$addressInput = this.$form.find('[name=address]');
        this.$cityInput = this.$form.find('[name=city]')
        this.$stateInput = this.$form.find('[name=state]');
        this.$zipInput = this.$form.find('[name=zip]');
        this.$zipPlusFourInput = this.$form.find('[name=zip-plus-four]')
        this.$premiseTypeInput = this.$form.find('[name=premiseType]');
        this.$settlementZoneInput = this.$form.find('[name=planSettlementZone]');
        this.$fromCheckoutInput = this.$form.find('[name=fromCheckout]');
        this.$fromDetailPageInput = this.$form.find('[name=fromDetailPage]');

        this.$intelometryError = this.$form.find('#updateModalIntelometryServerError');
        this.$unavailableError = this.$form.find('#updateModalUnavailable');

        this.$energyBotWrapper = this.$form.find('.js-energy-bot-wrapper');
        this.$addressDisplayWrapper = this.$form.find('.js-chosen-address-display-wrapper');
        this.$addressDisplayArea = this.$addressDisplayWrapper.find('.js-chosen-address');

        this.$chooseAnotherAddress = this.$addressDisplayWrapper.find('.js-choose-another-address');
    }

    render() {
        if (!this.$container.length) {
            return;
        }

        this.$form.on('submit', this.submitForm);

        this.$container.on('shown.bs.modal', this.onShowModal);
        this.$container.on('hidden.bs.modal', this.onHideModal);

        this.$chooseAnotherAddress.on('click', this.onChooseAnotherAddress);

        window.addEventListener('message', this.energyBotEventListener);
    }

    onChooseAnotherAddress = (event) => {
        event.preventDefault();

        this.$addressDisplayWrapper.hide();
        this.$submitBtn.hide();
        this.$energyBotWrapper.show();
        this.resetInputs();
    }

    onShowModal = () => {
    }

    onHideModal = () => {
        // hide all the errors and status messages and reset the form
        this.$unavailableError.hide();
        $("#updateModalInvalid").hide();
        this.$intelometryError.hide();
        $("#updateModalInvalidReasons").addClass("d-none");
        this.$addressModalBody.removeClass("was-validated");
        this.$submitBtn.hide();
        this.$addressDisplayWrapper.hide();
        this.$energyBotWrapper.show();

        this.resetInputs();
    }

    resetInputs = () => {
        this.$esiIdInput.val('');
        this.$utilityNameInput.val('');
        this.$addressInput.val('');
        this.$cityInput.val('');
        this.$stateInput.val('');
        this.$zipInput.val('');
        this.$zipPlusFourInput.val('');
    }

    submitForm = event => {
        event.preventDefault();
        event.stopPropagation();

        this.$addressModalBody.hide();
        this.$loader.show();
        this.$intelometryError.hide();

        const data = {
            esiId: this.$esiIdInput.val(),
            utilityName: this.$utilityNameInput.val(),
            streetAddress: this.$addressInput.val(),
            city: this.$cityInput.val(),
            state: this.$stateInput.val(),
            zip: this.$zipInput.val(),
            zipPlusFour: this.$zipPlusFourInput.val(),
            premiseType: this.$premiseTypeInput.val(),
            settlementZone: this.$settlementZoneInput.val(),
            fromCheckout: this.isCheckout(),
            fromDetailPage: this.isDetailPage()
        };     
        axios.post(`/api/address/updateaddresswithesiid`, data)
            .then((res) => {
                this.$form.trigger("updateAddress", [data]);
                this.onUpdateAddressSuccess(res); 
            })
            .catch((err) => {
                this.$form.trigger("updateAddressError", [err]);
                this.onUpdateAddressError(err);
            });
    }

    isCheckout = () => this.$fromCheckoutInput.val() === 'True';

    isDetailPage = () => this.$fromDetailPageInput.val() === 'True';

    onUpdateAddressSuccess = response => {
        const planCode = $("#planCode").val();
        const planName = $("#planName").val();
        
        if (response.data.error) {
            switch (response.data.error) {
                case "unavailable":
                    this.$addressModalBody.show();
                    this.$loader.hide();
                    this.$unavailableError.show();
                    break;
            }
        } else if (response.data.continuationUrl) {
            window.location = response.data.continuationUrl;
        } else if (response.data.action === 'reload-or-add-to-cart') {
            if (planCode && planName) {
                const body = {
                    code: planCode,
                    title: planName,
                    type: 'default'
                };

                axios.post('/api/cart', body)
                    .then((res) => {
                        this.$form.trigger("addToCart", [body]);
                        this.onAddToCartSuccess(res);
                    })
                    .catch((err) => {
                        this.$form.trigger("addToCartErr", [err]);
                        this.onAddToCartError(err);
                    });
            } else {
                window.location.reload();
            }
        }
        else if (response.data.action === 'reload-detail-page') {
            window.location = window.location.pathname + "?reloaded=True";
        }
    }

    onUpdateAddressError = error => {
        this.$addressModalBody.show();
        this.$loader.hide();
        this.$intelometryError.show();
    }

    // #energybot
    energyBotEventListener = (event) => {
        if (event && event.data) {            
            const data = tryParseJson(event.data);
            
            if (data === undefined) {
                return;
            }

            if (data.channel !== 'eb-esid-search') {
                return;
            }

            if (data.type === 'address-entered') {
                //console.log('*** address-entered 101 ***')
            } else if (data.type === 'address-selected') {
                console.log('*** address-selected 103 ***');
                try {
                    this.$esiIdInput.val(data.utilityAccountNumber);
                    this.$utilityNameInput.val(data.utility);
                    this.$addressInput.val(data.address.street);
                    this.$cityInput.val(data.address.city);
                    this.$stateInput.val(data.address.state);
                    this.$zipInput.val(data.address.zipCode);
                    this.$zipPlusFourInput.val(data.address.zipCodePlusFour);
                    this.$energyBotWrapper.hide();

                    this.$form.find('.js-chosen-address-display-wrapper').show();

                    this.$addressDisplayWrapper.show();
                    this.$addressDisplayArea.text(data.displayAddress)
                    this.$submitBtn.show();
                } catch (e) {
                    console.log("*** energybot address error ***");
                }
            }
        }
    }

    onAddToCartSuccess = (response) => {
        //Get settlement zone from address
        if (response.status == "200") {
            window.location = response.data;
        }
    }

    onAddToCartError = (error) => {
        debugger;
        if (error?.response?.data?.refresh) {
            var url = new URL(window.location.href);
            var inputParams = new URLSearchParams(url.search);
            inputParams.append("showRedirectModal", true);
            inputParams.append("redirectReason", error?.response?.data?.error)
            location.search = inputParams.toString();
            return;
        }
        this.$container.modal("hide");
        $("#addToCartErrorModal").modal("show");
        $("#addToCartErrorModalMessage").text(error?.response?.data?.error);
        console.log("!!! Add To Cart Failed - " + error?.response?.data?.error);
    }
}

const initFullAddressModal = () => {
    new FullAddressModalComponent().render();
}

export default initFullAddressModal;