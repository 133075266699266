const initTabsContainer = () => {
    $('.tabs-container ul.tabs-list li:first-child, .tabs-container .tab-contents-main .singletabcontainer:first-child').addClass('active');
    $('.tabs-container ul.tabs-list li:first-child').click(function () {
        $(this).addClass('active').siblings().removeClass('active');
        $('.tabs-container .tab-contents-main .singletabcontainer:first-child').addClass('active').siblings().removeClass('active');
    });
    $('.tabs-container ul.tabs-list li:nth-child(2)').click(function () {
        $(this).addClass('active').siblings().removeClass('active');
        $('.tabs-container .tab-contents-main .singletabcontainer:nth-child(2)').addClass('active').siblings().removeClass('active');
    });
    $('.tabs-container ul.tabs-list li:nth-child(3)').click(function () {
        $(this).addClass('active').siblings().removeClass('active');
        $('.tabs-container .tab-contents-main .singletabcontainer:nth-child(3)').addClass('active').siblings().removeClass('active');
    });
    $(".extendedformcontainerblock .Form__MainBody .Form__Element button[type='submit']").html('Submit <img src="/Assets/images/button-arrow.png" alt="NextVolt Energy Arrow" />');
};

export default initTabsContainer;
