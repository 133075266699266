import { loadStripe } from '@stripe/stripe-js';

const fonts = [{
    cssSrc: 'https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap'
}]

const appearance = {
    theme: 'stripe',
    variables: {
        colorPrimary: '#0570de',
        colorBackground: '#ffffff',
        colorText: '#30313d',
        colorDanger: '#df1b41',
        fontFamily: 'Outfit, sans-serif',
        fontSmooth: 'always',
        colorText: '#000000',
        colorPrimary: '#86b7fe',
        colorSuccess: '#198754',
        colorDanger: '#dc3545',
        spacingUnit: '4px',
        fontSizeBase: '20px',
        fontLineHeight: '30px',
        borderRadius: '0',
        gridColumnSpacing: '1rem',
        gridRowSpacing: '1rem'
    }
};

export const initializeAutopayPaymentForm = async (form) => {
    initializePaymentForm(form);

    const publicKey = form.dataset.publicKey;
    const methodsConfiguration = form.dataset.methodsConfiguration;
    const contactInfo = JSON.parse(form.dataset.stripeContact);
    const messageContainer = form.querySelector('#stripe-error-message')

    const stripe = await loadStripe(publicKey);
    const elements = stripe.elements({
        mode: 'setup',
        currency: 'usd',
        paymentMethodConfiguration: methodsConfiguration,
        appearance: appearance,
        fonts: fonts
    });

    const addressElement = createAddressElement(elements, contactInfo);
    const paymentElement = createPaymentElement(elements, contactInfo, messageContainer);

    addressElement.mount('#stripe-address-element');
    paymentElement.mount('#stripe-payment-element');

    return { stripe, elements };
}

export const initializeDepositPaymentForm = async (form) => {
    initializePaymentForm(form);

    const publicKey = form.dataset.publicKey;
    const methodsConfiguration = form.dataset.methodsConfiguration;
    const depositAmount = parseInt(form.dataset.depositAmount);
    const contactInfo = JSON.parse(form.dataset.stripeContact);
    const messageContainer = form.querySelector('#stripe-error-message')

    const stripe = await loadStripe(publicKey);
    const elements = stripe.elements({
        mode: 'payment',
        currency: 'usd',
        capture_method: 'manual',
        amount: depositAmount,
        paymentMethodConfiguration: methodsConfiguration,
        appearance: appearance,
        fonts: fonts
    });

    const addressElement = createAddressElement(elements, contactInfo);
    const paymentElement = createPaymentElement(elements, contactInfo, messageContainer);

    addressElement.mount('#stripe-address-element');
    paymentElement.mount('#stripe-payment-element');

    return { stripe, elements };
}

const initializePaymentForm = async (form) => {
    // initalize button behavior
    const payOnlineBtn = form.querySelector('#radio-pay-online');
    const payByPhoneBtn = form.querySelector('#radio-pay-by-phone');
    const depositExemptBtn = form.querySelector('#radio-deposit-exempt');

    const payOnlineTab = form.querySelector('#pay-online-tab')
    const payByPhoneTab = form.querySelector('#pay-by-phone-tab');
    const depositExemptTab = form.querySelector('#deposit-exempt-tab');

    const chosenMethodHdn = form.querySelector('[name=chosen-method]');
    const submitBtn = form.querySelector('button[type=submit]');

    const previousError = form.dataset.paymentError;

    payOnlineBtn?.addEventListener('click', () => {
        submitBtn.style.display = null;
        payOnlineTab.style.display = null;

        if (payByPhoneTab != null)
            payByPhoneTab.style.display = 'none';

        if (depositExemptBtn != null)
            depositExemptTab.style.display = 'none';

        chosenMethodHdn.value = 'deposit-method';
    });

    payByPhoneBtn?.addEventListener('click', () => {
        submitBtn.style.display = null;
        payOnlineTab.style.display = 'none';

        if (payByPhoneTab != null)
            payByPhoneTab.style.display = null;

        if (depositExemptBtn != null)
            depositExemptTab.style.display = 'none';

        chosenMethodHdn.value = 'phone';
    });

    depositExemptBtn?.addEventListener('click', () => {
        submitBtn.style.display = null;
        payOnlineTab.style.display = 'none';

        if (payByPhoneTab != null)
            payByPhoneTab.style.display = 'none';

        if (depositExemptBtn != null)
            depositExemptTab.style.display = null;

        chosenMethodHdn.value = 'deposit-exempt';
    });

    if (previousError) {
        payOnlineBtn.checked = true;
        payOnlineTab.style.display = null;
        submitBtn.style.display = null;
        chosenMethodHdn.value = 'deposit-method';

        const messageContainer = form.querySelector('#stripe-error-message');
        messageContainer.textContent = previousError;
    }
}

const createAddressElement = (elements, contactInfo) => {
    const options = {
        mode: 'billing',
        blockPoBox: true,
        allowedCountries: ['US'],
        defaultValues: {
            name: contactInfo?.fullName,
            phone: contactInfo?.phone,
            address: {
                line1: contactInfo?.address?.line1,
                line2: contactInfo?.address?.line2,
                city: contactInfo?.address?.city,
                state: contactInfo?.address?.state,
                postal_code: contactInfo?.address?.zipcode,
                country: 'US'
            }
        }
    };

    const addressElement = elements.create('address', options);
    return addressElement;
}

const createPaymentElement = (elements, contactInfo, messageContainer) => {
    const options = {
        defaultValues: {
            billingDetails: {
                name: contactInfo?.fullName,
                email: contactInfo?.email,
                phone: contactInfo?.phone,
                address: {
                    line1: contactInfo?.address?.line1,
                    line2: contactInfo?.address?.line2,
                    city: contactInfo?.address?.city,
                    state: contactInfo?.address?.state,
                    postal_code: contactInfo?.address?.zipcode,
                    country: 'US'
                }
            }
        },
        fields: {
            billingDetails: {
                name: 'never'
            }
        }
    }

    const paymentElement = elements.create('payment', options);
    paymentElement.on('change', (event) => clearErrorMessage(event, messageContainer));

    return paymentElement;
}

const clearErrorMessage = (event, messageContainer) => {
    if (event.complete)
        messageContainer.textContent = '';
}