
export const displayInvalidForm = (event) => {
    event.target.classList.add('was-validated');
    $('.was-validated :invalid').parent().addClass('error-code');
    $('.was-validated :valid').parent().removeClass('error-code');
    let _errorCodes = $('.error-code, .was-validated .is-invalid');
    if ($(_errorCodes).length) {
        $('html, body').animate({
            scrollTop: $(_errorCodes).first().offset().top - 180
        }, 300);
    }
}

export const cartCheckoutSuccess = (response) => {

    if (response.data.error) {
        $("#checkout-submit-text").show();
        $("#checkout-submit-spinner").hide();
        $("#checkout-submit-button").removeAttr("disabled");
        if (response.data.errorReason == "accountInUse") {
            $("#updateModalValid").addClass("d-none");
            $("#accountErrorModal").modal("toggle");
        } else {
            $("#updateModalValid").addClass("d-none");
            $("#checkoutErrorModalMessage").text(response.data.error);
            $("#checkoutErrorModalBody").addClass('d-none');
            $("#checkoutErrorModal").modal("toggle");
        }
    } else {
        window.location.href = response.data.href;
    }
}

export const cartCheckoutFailure = (error) => {
    $("#checkout-submit-text").show();
    $("#checkout-submit-spinner").hide();
    $("#checkout-submit-button").removeAttr("disabled");

    console.error(error);
    $("#updateModalValid").addClass("d-none");
    $("#updateModalServerError").removeClass("d-none");

    if (error.response.status == 400) {
        $("#checkoutValidationErrorModalBody").empty();

        const list = document.createElement('ul');

        for (const [_, errs] of Object.entries(error.response.data)) {
            for (const err of errs) {
                const listItem = document.createElement('li');
                listItem.textContent = err;
                list.appendChild(listItem);
            }
        }

        $("#checkoutValidationErrorModalBody").append(list);
        $("#checkoutValidationErrorModal").modal('toggle');
    } else {
        $("#checkoutErrorModal").modal("toggle");
    }
}

export const depositSuccess = (response) => {
    if (response.data.error) {
        //$("#updateModalValid").addClass("d-none");
        $("#checkoutErrorModalMessage").text(response.data.error);
        $("#checkoutErrorModalBody").addClass('d-none');
        $("#checkoutErrorModal").modal("toggle");
    } else {
        window.location.href = response.data.href;
    }
}

export const depositError = (error) => {
    console.error(error);
}