const appendCell = (row, text) => {
    const cell = document.createElement('td');
    cell.textContent = text;

    row.appendChild(cell);
};

const tryParseJson = text => {
    try {
        return JSON.parse(text);
    } catch {
        return undefined;
    }
}

const initEnergyBotTestPage = () => {
    const $container = $('#energy-bot-page');
    if (!$container.length) {
        return;
    }

    const $outputArea = $container.find('#output-area');
    const $outputTable = $container.find('#output-table');
    const $outputTableBody = $outputTable.find('tbody');

    window.addEventListener('message', event => {
        if (event && event.data) {
            const data = tryParseJson(event.data);

            if (data === undefined) {
                return;
            }

            if (data.channel !== 'eb-esid-search') {
                return;
            }

            if (data.type === 'address-entered') {
            } else if (data.type === 'address-selected') {
                $outputArea.text(JSON.stringify(data, null, 4));

                $outputTableBody.empty();

                const row = document.createElement('tr');

                appendCell(row, data.displayAddress);
                appendCell(row, data.utilityAccountNumber);

                $outputTableBody.append(row);
            }
        }
    })
};

export default initEnergyBotTestPage;