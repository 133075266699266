
const initSummaryPage = () => {
    $("#summary-submit-button").click(_disableSubmitButton);
    $("#summary-edit-button").click(_disableEditButton);
}

const _disableSubmitButton = (event) => {
    $("#summary-submit-text").hide();
    $("#summary-submit-spinner").show();
    $("#summary-submit-button").addClass("disabled");
    $("#summary-edit-button").addClass("disabled");
    document.getElementById("#summary-page-form").submit();
}

const _disableEditButton = (event) => {
    $("#summary-edit-text").hide();
    $("#summary-edit-spinner").show();
    $("#summary-edit-button").addClass("disabled");
    $("#summary-submit-button").addClass("disabled");
}

export default initSummaryPage;