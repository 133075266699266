import axios from 'axios';
import * as ld from 'lodash-es/lodash';

const registerFormSubmissionEvent = (formId, endpoint, inputMappings, callback, errorCallback, validationCallback) => {
    const $form = $(`#${formId}`);
    const $submitBtn = $form.find('.js-submit-btn');
    const $spinner = $submitBtn.find('.js-spinner');

    const csrfToken = $form.find('input[name="__RequestVerificationToken"]').val(); 

    $form.on('submit', event => {
        event.preventDefault();

        // don't allow submitting invalid forms
        if (validationCallback) {
            const isValid = validationCallback();

            $(event.target).addClass('was-validated');
            if (!isValid) {
                return;
            }
        }

        if ($(event.target).hasClass('needs-validation') && !event.target.checkValidity()) {
            $(event.target).addClass('was-validated');
            return;
        }

        $('#error-display').hide();

        
        $submitBtn.prop('disabled', true);
        $spinner.show();

        axios.post(
            `/api/accountinfo/${endpoint}`,
            ld.mapValues(inputMappings, name => $form.find(`[name=${name}]`).val()),
            {
                headers: {
                    RequestVerificationToken: csrfToken
                }
            })
            .then(res => callback(res.data))
            .catch(err => {
                if (errorCallback) {
                    errorCallback(err);
                } else if (err.response.data.detail) {
                    $('#error-display').text(err.response.data.detail);
                    $('#error-display').show();
                } else {
                    $('#error-display').text("Something went wrong. Please try again later and contact us if the problem persists.");
                    $('#error-display').show();
                }
            })
            .finally(() => {
                $spinner.hide();
                $submitBtn.prop('disabled', false);
            });
    })
}

const initAccountInformationPage = () => {
    const $form = $('#account-info-container');

    if (!$form.length) {
        return;
    }

    // toggles
    $form.find('#editName').on('click', () => {
        $form.find('.js-info-editing-form:not(#name-edit)').removeClass('open');
        $form.find('#name-edit').toggleClass('open');
        $(this).toggleClass('active');
    });

    $form.find('#editEmail').on('click', () => {
        $form.find('.js-info-editing-form:not(#email-edit)').removeClass('open');
        $form.find('#email-edit').toggleClass('open');
        $(this).toggleClass('active');
    });

    $form.find('#editPhone').on('click', () => {
        $form.find('.js-info-editing-form:not(#phone-edit)').removeClass('open');
        $form.find('#phone-edit').toggleClass('open');
        $(this).toggleClass('active');
    });

    $form.find('#editBilling').on('click', () => {
        $form.find('.js-info-editing-form:not(#billing-address-edit)').removeClass('open');
        $('#billing-address-edit').toggleClass('open');
        $(this).toggleClass('active ');
    });

    $form.find('#editPassword').on('click', () => {
        $form.find('.js-info-editing-form:not(#password-edit)').removeClass('open');
        $('#password-edit').toggleClass('open');
        $(this).toggleClass('active ');
    });

    // input masks
    $form.find('[name=phone]').inputmask('(999) 999-9999');

    // form submission
    registerFormSubmissionEvent('phone-edit', 'UpdateAccount', {
        Phone: 'phone',
        ContactType: 'contactType'
    }, res => {
        $form.find('#phoneNumberDisplay').text(res.phone);

        $form.find('#phone-edit').removeClass('open');
    });

    registerFormSubmissionEvent('email-edit', 'UpdateAccount', {
        NewEmail: 'newEmail',
        CurrentEmail: 'currentEmail',
        ContactType: 'contactType'
    }, res => {
        $form.find('#emailDisplay').text(res.email);
        $form.find('#currentEmail').text(res.email);

        $form.find('#email-edit').removeClass('open');
    });

    registerFormSubmissionEvent('name-edit', 'UpdateAccount', {
        FirstName: 'firstName',
        LastName: 'lastName',
        CurrentEmail: 'currentEmail',
        ContactType: 'contactType'
    }, res => {
        $form.find('#nameDisplay').text(`${res.firstName} ${res.lastName}`);

        $form.find('#name-edit').removeClass('open');
    });

    registerFormSubmissionEvent('billing-address-edit', 'UpdateBillingAddress', {
        AddressLine1: 'line1',
        AddressLine2: 'line2',
        City: 'city',
        Zip: 'zip',
        State: 'billingState'
    }, res => {
        $form.find('#billingAddressDisplay').text(res);

        $form.find('#billing-address-edit').removeClass('open');
    });

    registerFormSubmissionEvent('password-edit', 'UpdatePassword', {
        CurrentPassword: 'currentPassword',
        NewPassword: 'newPassword',
        ConfirmPassword: 'confirmPassword',
        CurrentEmail: 'currentEmail',
    }, res => {
        $form.find('#password-edit').removeClass('open');
    }, err => {
        if (err.response && err.response.data && err.response.data.error === 'incorrect-password') {
            $form.find('#password-edit').addClass('was-validated');
            $form.find('[name=currentPassword]')[0].setCustomValidity('Your current password is incorrect');
            $form.find('[name=currentPassword] + .invalid-feedback').text('Incorrect current password');

            $form.find('[name=currentPassword]').on('input', event => {
                event.target.setCustomValidity('');
            });
        } else if (err.response && err.response.data && err.response.data.error === 'weak-password') {
            $form.find('#password-edit').addClass('was-validated');
            $form.find('[name=newPassword]')[0].setCustomValidity('Password is not strong enough');
            $form.find('[name=newPassword] + .invalid-feedback').text('Password is not strong enough');

            $form.find('[name=newPassword]').on('input', event => {
                event.target.setCustomValidity('');
            });
        } else {
            $('#error-display').show();
        }
    }, () => {
        if ($form.find('[name=confirmPassword]').val() != $form.find('[name=newPassword]').val()) {
            $form.find('[name=confirmPassword]')[0].setCustomValidity('Passwords must match');
            $form.find('[name=confirmPassword]').on('input', event => {
                event.target.setCustomValidity('');
            });

            return false;
        }

        return true;
    });

    $form.find('.js-close-editor').on('click', event => {
        event.preventDefault();
        $(event.target).closest('.edit-info').removeClass('open');
    });
};

export default initAccountInformationPage;
