import axios from 'axios';

const initCommerceButtons = () => {
    $('.js-add-plan-to-cart-button').on('click', event => {
        event.preventDefault();

        const $sender = $(event.currentTarget);
        const code = $sender.data('variantCode');
        const title = $sender.data('variantName');

        const data =  {
            code: code,
            title: title,
            type: 'default'
        };

        axios.post('/api/cart', data)
            .then(function (response) {
                //Get settlement zone from address
                console.log('response', response);
                if (response.status == "200") {
                    console.log('added to cart');
                    $sender.trigger("addToCart", [data]);
                    window.location = response.data;
                }
            })
            .catch(function (error) {
                // ADD TO CART FAILED
                $sender.trigger("addToCartError", [error]);
                $("#editServiceAddressModal").modal("hide");
                $("#addToCartErrorModal").modal("show");
                $("#addToCartErrorModalMessage").text(error);
                console.log("!!! Add To Cart Failed - " + error);
            });
    });

    $('.js-address-modal-button').on('click', event => {
        event.preventDefault();

        const $sender = $(event.currentTarget);
        const code = $sender.data('variantCode');
        const title = $sender.data('variantName');

        $("#editServiceAddressModal").modal("show");
        $("#planName").val(title);
        $("#planCode").val(code);
    })

    $('.js-empty-cart').on('click', event => {
        event.preventDefault();

        axios.delete('/api/cart')
            .then(function (response) {
                if (response.status == "200") {
                    console.log('cart emptied');
                    $sender.trigger("emptyCart");
                    window.location.reload();
                }
            })
            .catch(function (error) {
                console.log(error);
                $sender.trigger("emptyCartError", [error]);
            });
    });

    $('.js-only-active-form').on('submit', event => {
        event.preventDefault();

        const $form = $(event.target);

        const onlyActive = $form.find('[name=onlyActive]').is(':checked') ? 'true' : '';
        location.href = location.pathname + `?onlyActive=${onlyActive}`;
    });
};

export default initCommerceButtons;
