const initBackToTopButton = () => {
    // Back To Top
    $('#backToTop').on('click', function () {
        var _this = $(this);
        $("html, body").stop(true, true);
        $("html, body").animate({ scrollTop: 0 }, 350, 'linear', function () {
            $(_this).blur();
        });
    }).on('mouseenter focus', function () { 
        $(this).addClass('opacity-100');
    }).on('mouseleave blur', function () {
        $(this).removeClass('opacity-100');
    });

    $('#backToTop').on('focus', function () {
        var _this = $(this);
        $(this).bind('keyup.BackToTop', function (event) {
            if (event.which == 13) {
                $(_this).trigger('click');
            }
        });
    }); 

    $('#backToTop').on('blur', function () {
        $(this).unbind('keyup.BackToTop');
    });

    $('#backToTop').on('mouseleave', function () {
        $(this).blur();
    }); 

};

export default initBackToTopButton;
