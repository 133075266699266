import axios from 'axios';

export const axiosPost = (url, data, callback, errCallback) => {
    axios.post(url, data)
        .then((response) => {
            callback(response);
        })
        .catch((error) => {
            errCallback(error);
        })
}

export const axiosPostHeaders = (url, data, headers, callback, errCallback) => {
    axios.post(url, data, headers)
        .then((response) => {
            callback(response);
        })
        .catch((error) => {
            errCallback(error);
        })
}

export const axiosGet = (url, queryParams, callback, errCallback) => {
    const params = new URLSearchParams(queryParams);
    axios.get(`${url}?${params}`)
        .then((response) => {
            callback(response);
        })
        .catch((error) => {
            errCallback(error);
        });
}