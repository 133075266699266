import { axiosGet } from '../api/api.js';
import ld from 'lodash-es/lodash';

const initGridBlock = () => {

    $('.grid-block-wrapper #redirectedModal').modal('toggle');
    $('.grid-block-wrapper #utilitySelectModal').modal('toggle');
    $('.grid-block-wrapper #serviceUnavailableModal').modal('toggle');

    var gridBlock = document.querySelector('grid-block-wrapper');
    if (gridBlock?.dataset?.outOfState) {
        location.href.replace(`${gridBlock.dataset.outOfState}`);
    }

    $('.grid-block-wrapper .plan-grid-item .more-info').each(function () {
        $(this).click(function () {
            $(this).closest('.main-view').toggleClass('open');
        });
    });

    $('.grid-block-wrapper .js-grid-zip-code-upgrade-form').on('submit', _submitUpdateZipCodeForm);

    $('.grid-block-wrapper #utility-select-radio-group').on('click', 'input[type=radio]', (event) => {

        const utility = $(event.target).val();

        const baseHref = $('#utility-select-link').data('baseHref');
        const updatedHref = `${baseHref}&utility=${utility}`;

        $('#utility-select-link').attr('href', updatedHref);
    });
};

const _submitUpdateZipCodeForm = (event) => {

    event.preventDefault();

    const values = ld.mapValues(ld.keyBy($(event.target).serializeArray(), 'name'), 'value');
    
    const url = `/api/lookupSettlementZoneByZipCode/${values.gridType}/${values.gridZip}`;

    axiosGet(url, {}, (res) => {
        _lookupApiSuccess(res);
    }, (err) => {
        _lookupApiFailure(err);
    });
};

const _lookupApiSuccess = (response) => {
    if (response.data.action == 2 && response.data.settlementZone != null) {
        // found settlement zone with zip code, redirect to corresponding plan grid
        // redirect to residential plan grid

        window.location.href = `${location.pathname}?zipCode=${response.data.zipCode}&settlementZone=${response.data.settlementZone}`;
        return;
    }

    if (response.data.action == 4) {
        window.location.href = `${response.data.href}`;
        return;
    }

    $('#editServiceAddressModal').modal('show');
    $('#updateModalValid').addClass('d-none');
    $('#updateModalInvalid').removeClass('d-none');
};

const _lookupApiFailure = (error) => {

    if ($("#invalidZipCodeErrorMessage").is(':visible')) {
        $("#invalidZipCodeErrorMessage").hide();
    }

    $("#serviceUnavailableModal").modal('toggle');
}; 

export default initGridBlock;