const initBlogCarousel = () => {
    $(".blog-carousel").owlCarousel({
        autoplay: false,
        items: 3,
        loop: true,
        nav: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        freeDrag: false,
        margin: 0,
        stagePadding: 0,
        merge: false,
        mergeFit: true,
        autoWidth: false,
        startPosition: 0,
        rtl: false,
        smartSpeed: 250,
        fluidSpeed: false,
        dragEndSpeed: false,
        autoHeight: true,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2
            },
            992: {
                items: 2
            }
        },
        navText: ['<span class="de-arrow-left" aria-hidden="true"></span > ', ' <span class="de-arrow-right" aria-hidden="true"></span>']
    });
}

const initBlogComponents = () => {
    initBlogCarousel();
};

export default initBlogComponents;
