const VerifyAddressModal = (() => {
    let state = null;

    const constants = {
        addressOptionInputName: 'addressOption',
        addressOptionContainerSelector: 'div.address-option',
        addressOptionContainerSelectedClass: 'selected'
    }

    const open = (modalElement, enteredAddress, suggestedAddresses) => {
        const modalInstance = $(modalElement).modal({
            backdrop: 'static',
            keyboard: false
        }).get(0);

        state = {
            wasSaved: false,
            originalAddress: enteredAddress,
            selectedAddress: enteredAddress,
            modalInstance: modalInstance,
            itemTemplate: modalInstance.querySelector("template#address-option-template"),
            enteredAddressContainer: modalInstance.querySelector("div#entered-address-list"),
            suggestedAddressesWrapper: modalInstance.querySelector("div#suggested-addresses-wrapper"),
            suggestedAddressesContainer: modalInstance.querySelector("div#suggested-address-list"),
            noChoiceAlert: modalInstance.querySelector("div#no-choice-alert"),
            noMatchesAlert: modalInstance.querySelector("div#no-matches-alert"),
            saveButton: modalInstance.querySelector("button.save-button"),
            dismissButton: modalInstance.querySelector("button.cancel-button")
        };

        state.saveButton.addEventListener('click', onSaveClick);
        state.dismissButton.addEventListener('click', onDismissClick);

        if (suggestedAddresses == null || suggestedAddresses.length == 0) {
            state.noMatchesAlert.classList.remove('d-none');
            state.suggestedAddressesWrapper.classList.add('d-none');
        }

        stampTemplate(enteredAddress, suggestedAddresses);

        $(modalInstance).modal('show');

        const modalPromise = new Promise((resolve, reject) => {
            modalElement.addEventListener('hidden.bs.modal', () => {
                if (state.wasSaved)
                    resolve(state.selectedAddress);
                else
                    reject();
            });
        }).finally(() => {
            state.suggestedAddressesWrapper.classList.remove('d-none');
            state.noChoiceAlert.classList.add('d-none');
            state.noMatchesAlert.classList.add('d-none');
            state.saveButton.removeEventListener('click', onSaveClick);
            state.dismissButton.removeEventListener('click', onDismissClick);
            state.enteredAddressContainer.textContent = '';
            state.suggestedAddressesContainer.textContent = '';
        });

        return modalPromise;
    }

    const stampTemplate = (enteredAddress, suggestedAddresses) => {
        //entered address;
        const enteredAddressItem = createAddressItem(enteredAddress);
        state.enteredAddressContainer.replaceChildren(enteredAddressItem);

        //suggested addresses
        const suggestedAddressItems = suggestedAddresses.map(x => createAddressItem(x));
        state.suggestedAddressesContainer.replaceChildren(...suggestedAddressItems);
    }

    const createAddressItem = (simpleAddress) => {
        const item = state.itemTemplate.content.firstElementChild.cloneNode(true);
        const radioButton = item.querySelector('input[type="radio"]');
        const label = item.querySelector('label');

        radioButton.name = constants.addressOptionInputName;
        radioButton.addEventListener('click', (event) => onAddressSelected(event, simpleAddress));

        label.insertAdjacentHTML('afterbegin', `${simpleAddress.line1 ?? ''} ${simpleAddress.suite ?? ''}<br/>
            ${simpleAddress.city ?? ''}, ${simpleAddress.state ?? ''} ${simpleAddress.zipcode ?? ''}`);

        return item;
    }

    const onAddressSelected = (event, simpleAddress) => {
        state.noChoiceAlert.classList.add('d-none');

        state.selectedAddress = simpleAddress;
        const addressOptions = Array.from(state.modalInstance.querySelectorAll(`input[name="${constants.addressOptionInputName}"]`));

        for (const addressOption of addressOptions) {
            addressOption.closest(constants.addressOptionContainerSelector)
                .classList
                .remove(constants.addressOptionContainerSelectedClass);
        }

        event.currentTarget.closest(constants.addressOptionContainerSelector)
            .classList
            .add(constants.addressOptionContainerSelectedClass);
    }

    const onSaveClick = () => {
        const checkedItem = state.modalInstance.querySelector(`input[name="${constants.addressOptionInputName}"]:checked`);
        if (checkedItem == null) {
            state.noChoiceAlert.classList.remove('d-none');
            return;
        }

        state.wasSaved = true;
        $(state.modalInstance).modal('hide');
    }

    const onDismissClick = () => {
        state.wasSaved = false;
        state.selectedAddress = state.originalAddress;
        $(state.modalInstance).modal('hide');
    }

    return {
        open: open
    };
})();

export default VerifyAddressModal;
