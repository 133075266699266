import axios from 'axios';

import { initializeDepositPaymentForm } from '../helpers/enrollment-payment-form.js';

const handleError = ($form, error) => {
    const messageContainer = $form.find('#stripe-error-message');
    messageContainer.text(error.message);

    toggleForm($form, false);
}

const toggleForm = ($form, disable) => {
    const $submitBtn = $form.find('button[type=submit]');
    const $spinner = $form.find('.js-spinner');

    $submitBtn.prop('disabled', disable);

    if (disable)
        $spinner.show();
    else
        $spinner.hide();
}

const submitPayByToken = async ($form, isCsr, stripe, elements) => {
    toggleForm($form, true);

    const csr = $form[0].dataset.csr === true;
    const csrfToken = $form[0].querySelector('input[name="__RequestVerificationToken"]')?.value;
    const useForAutopayCheckbox = $form[0].querySelector('#use-for-autopay')?.checked === true;
    const returnUrl = $form[0].dataset.redirectUrl;

    try {
        const { error: submitError } = await elements.submit();

        if (submitError) {
            handleError($form, submitError);
            return;
        }

        const res = await fetch("/api/cart/create-deposit-intent", {
            headers: {
                "Content-Type": "application/json",
                RequestVerificationToken: csrfToken
            },
            body: JSON.stringify({
                useForAutopay: useForAutopayCheckbox,
                isCsr: csr
            }),
            method: "POST",
        });

        const { success, errorMessage, clientSecret } = await res.json();

        if (!success) {
            handleError($form, errorMessage);
            return;
        }

        const params = {
            elements,
            clientSecret: clientSecret,
            confirmParams: {
                return_url: returnUrl
            },
        }
        const { error } = await stripe.confirmPayment(params);

        if (error) handleError($form, error);
    } catch (err) {
        handleError($form, err);
        console.error(err);
    } finally {
        toggleForm($form, false);
    }
}

const submitPayByPhone = ($form) => {
    const csrfToken = $form.find('input[name="__RequestVerificationToken"]').val();

    const $submitBtn = $form.find('button[type=submit]');
    const $spinner = $form.find('.js-spinner');

    $submitBtn.prop('disabled', true);
    $spinner.show();

    axios.post(`/api/cart/pay-by-phone`, null, {
        headers: {
            RequestVerificationToken: csrfToken
        }
    }).then(res => {
        $form.trigger("deposit", [{ endpoint: "pay-by-phone" }]);
        window.location.href = res.data.href;
    }).catch(err => {
        $form.trigger("depositError", [err]);
        $form.find('.js-error-modal').modal('show');
    }).finally(() => {
        $spinner.hide();
        $submitBtn.prop('disabled', false);
    });
};

const submitDepositExempt = ($form) => {
    const csrfToken = $form.find('input[name="__RequestVerificationToken"]').val();

    const submitBtn = $form.find('button[type=submit]');
    const spinner = $form.find('.js-spinner');

    submitBtn.prop('disabled', true);
    spinner.show();

    axios.post(`/api/cart/csr-deposit-exempt`, null, {
        headers: {
            RequestVerificationToken: csrfToken
        }
    }).then(res => {
        $form.trigger("deposit", [{ endpoint: "csr-deposit-exempt" }]);
        window.location.href = res.data.href;
    }).catch(err => {
        $form.trigger("depositError", [err]);
        $form.find('.js-error-modal').modal('show');
    }).finally(() => {
        $spinner.hide();
        $submitBtn.prop('disabled', false);
    });
};

const initDepositPage = async () => {
    const container = document.querySelector('.js-deposit-page');
    if (container == null)
        return;

    const form = container.querySelector('#deposit-form');
    const isCsr = false;

    const { stripe, elements } = await initializeDepositPaymentForm(form);

    form.addEventListener('submit', async e => {
        e.preventDefault();

        const method = form.querySelector('[name=chosen-method]').value;

        switch (method) {
            case 'phone':
                submitPayByPhone($(form));
                return;
            case 'deposit-exempt':
                submitDepositExempt($(form));
                return;
            case 'deposit-method':
                await submitPayByToken($(form), isCsr, stripe, elements);
                return;
            default:
                return;
        }
    });

    const useForAutopayCheckbox = form.querySelector('#use-for-autopay');

    useForAutopayCheckbox?.addEventListener('click', (event) => {
        elements.update({
            setup_future_usage: event.target.checked
                ? 'off_session'
                : null
        });
    });
}

export default initDepositPage;