const initAnnouncementBar = () => {
    if ($('header .announcement-bar').is(':visible')) {
        $('.checkout-page').addClass('announcement-on');
    }

    var barHeight = $('.announcement-bar').outerHeight();
    $('header').css('padding-top', barHeight);

    $('.announcement-bar .de-close').click(function () {
        $('.announcement-bar').hide();
        $('.checkout-page').removeClass('announcement-on');
        $.cookie("announcement", "seen", { path: '/' });
    });
    
    if (!$.cookie('announcement')) {
        $('.announcement-bar').show();
        $('.checkout-page').addClass('announcement-on');
    }
};

export default initAnnouncementBar;
