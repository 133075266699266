const initHealthCheckPage = () => {
    const $hcTable = $('.health-check-wrapper');
    if (!$hcTable.length) {
        return;
    }

    $(document).ready(function () {
        $('#healthCheckTable').DataTable();
    });
}
export default initHealthCheckPage;