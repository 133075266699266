import { axiosGet } from '../api/api.js';

const initCsrBanner = () => {
    $("#new-csr-order").click(newCsrOrder);
    $("#csr-logout").click(csrLogout);
    $(".new-csr-order").click(newCsrOrder);
}

const newCsrOrder = (event) => {
    axiosGet("/api/user/newCsrOrder", {}, _onSuccess, _onFailure);
}

const _onSuccess = (response) => {
    window.location = response.data;
}

const _onFailure = (error) => {
    console.error(error);
    window.location = response.data;
}

const csrLogout = (event) => {
    axiosGet("/api/user/csrLogout", {}, _logoutSuccess, _logoutFailure);
}

const _logoutSuccess = (response) => {
    window.location = response.data;
}

const _logoutFailure = (error) => {
    console.error(error);
    window.location.reload;
}

export default initCsrBanner;