import Rollbar from 'rollbar';

// rollbar will ignore any errors matching these regexes
const ignoredMessages = [
    /this is another test/
];

let rollbar;

const getRollbar = () => {
    if (!rollbar) {
        rollbar = new Rollbar({
            accessToken: $('.js-rollbar-config').data('accessToken'),
            environment: $(document.body).data('environment'),
            captureUncaught: true,
            captureUnhandledRejections: true,
            hostSafeList: [],
            ignoredMessages,
            checkIgnore: function (isUncaught, args, payload) {
                const msgText = args[0];
    
                return ignoredMessages.some(pattern => pattern.test(msgText));
            }
        });
    }
    
    return rollbar;
};

export default getRollbar;
