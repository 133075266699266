const initLiveChat = () => {
    if (window.icPatronChat) {
        const $liveChatConfigElement = $('.js-live-chat-settings');
        const businessNumber = $liveChatConfigElement.data('businessNumber');
        const poc = $liveChatConfigElement.data('poc');

        window.icPatronChat.init({
            serverHost:'https://home-c35.nice-incontact.com',
            bus_no: businessNumber,
            poc,
            params:['','','','']
        }); // "params" is [first name, last name, email, phone]
    }
};

export default initLiveChat;
