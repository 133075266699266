import { axiosGet } from '../api/api.js';
import * as ld from 'lodash-es/lodash';

const initZipCodeCtaBlock = () => {

    $('.zip-code-block-main #zip-code-cta-form').on('submit', _submitZipCodeCtaForm);

    $('.zip-code-block-main #utility-select-radio-group').on('click', 'input[type=radio]', (event) => {

        const utility = $(event.target).val();

        //console.log('Utility Radio Button Clicked:', utility);

        const baseHref = $('#utility-select-link').data('baseHref');
        const updatedHref = `${baseHref}&utility=${utility}`;

        //console.log('Updated Href for Select Utility Link:', updatedHref);

        $('#utility-select-link').attr('href', updatedHref);
    });
};

const _submitZipCodeCtaForm = (event) => {

    event.preventDefault();

    if (event.target) {

        const form = event.target;

        if (form.zipCode && form.radioResidential) {

            const zipCode = form.zipCode.value;
            const residentialFlag = form.radioResidential.checked;

            if (!_isValidZipCode(zipCode)) {
                $("#invalidZipCodeErrorMessage").show();
                return;
            }

            var zoneType = "business";

            if (residentialFlag) {
                zoneType = "residential";
            }

            const url = `/api/lookupSettlementZoneByZipCode/${zoneType}/${zipCode}`;

            //console.log('URL:', url);

            axiosGet(url, {}, (res) => {
                _lookupApiSuccess(res);
            }, (err) => {
                _lookupApiFailure(err);
            });

        } else {
            $("#invalidZipCodeErrorMessage").show();
            return;
        }
        
    } else {
        $("#invalidZipCodeErrorMessage").show();
        return;
    }
};

const _lookupApiSuccess = (response) => {
    if (response.data.action == 2 && response.data.settlementZone != null) {
        // found settlement zone with ZIP code, redirect to corresponding plan grid
        //console.log('Found matching settlement zone', response.data.settlementZone);
        //console.log('Zone Type:', response.data.zoneType);
        //console.log('ZIP Code:', response.data.zipCode);
        //console.log('Utility Name:', response.data.utilityName);
        //console.log('Utility Label:', response.data.utilityLabel);

        location.href = `${response.data.href}?zipCode=${response.data.zipCode}&settlementZone=${response.data.settlementZone}`;
        return;
    }

    if (response.data.action == 4) {
        location.href = `${response.data.href}`;
        return;
    }

    if(response.data.action == 3) {
        //multiple settlement zones for ZIP code
        //console.log('Multiple settlement zones');
        //console.log('Zone Type:', response.data.zoneType);
        //console.log('ZIP Code:', response.data.zipCode);
        //console.log('Utility Names:', response.data.utilityNames);
        //console.log('Utility Labels:', response.data.utilityLabels);

        if ($("#invalidZipCodeErrorMessage").is(':visible')) {
            $("#invalidZipCodeErrorMessage").hide();
        }
        
        _populateUtilityRadioButtons(response.data.utilityNames, response.data.utilityLabels);
        _populateUtilitySelectLink(response.data.href, response.data.zipCode, response.data.settlementZone, response.data.utilityNames);
        
        $("#utilitySelectModal").modal('toggle');
        return;
    }

    if (response.data.action == 1) {
        //service unavailable for ZIP code
        //console.log('Service unavailable');
        //console.log('Zone Type:', response.data.zoneType);
        //console.log('ZIP Code:', response.data.zipCode);            

        if ($("#invalidZipCodeErrorMessage").is(':visible')) {
            $("#invalidZipCodeErrorMessage").hide();
        }
        
        $("#serviceUnavailableModal").modal('toggle');
        return;
    }
};

const _lookupApiFailure = (error) => {

    //console.error(error);

    if ($("#invalidZipCodeErrorMessage").is(':visible')) {
        $("#invalidZipCodeErrorMessage").hide();
    }

    $("#serviceUnavailableModal").modal('toggle');
};

const _isValidZipCode = (zip) => {
    var valid = zip.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
    return valid != null ? true : false;
};

const _populateUtilityRadioButtons = (utilityNames, utilityLabels) => {

    const innerHtml = ld.reduce(utilityNames, (acc, utilName, idx) =>
    {
        return acc + `<div><input type="radio" id="${utilName}" name="utility" value="${utilName}" ${idx==0 ? 'checked' : ''}><label class="px-2" for="${utilName}">${ld.get(utilityLabels, idx, utilName)}</label></div>`;
    }, '');

    $('#utility-select-radio-group').html(innerHtml);
};

const _populateUtilitySelectLink = (href, zipCode, settlementZone, utilityNames) => {
    
    const firstUtilityName = ld.chain(utilityNames).get('0').value();
    
    const baseHref = `${href}?zipCode=${zipCode}&settlementZone=${settlementZone}`;
    //console.log('Base Href for Select Utility Link:', baseHref);

    const initialHref = `${baseHref}&utility=${firstUtilityName}`;
    //console.log('Initial Href for Select Utility Link (First Utility):', initialHref);

    $('#utility-select-link').data('baseHref', baseHref);
    $('#utility-select-link').attr('href', initialHref);
};

export default initZipCodeCtaBlock;